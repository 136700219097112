.query-item {
  background-color: $primary;
  padding: 5px;
  margin: 0 4px 10px;
  position: relative;

  a {
    img {
      width: 20px;
      vertical-align: top;
      margin-right: 5px;
    }

    color: $gray-500;
    text-decoration: none;
  }

  button {
    &:hover {
      color: $pink;
      text-decoration: none;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: -4px;
    width: calc(100% + 8px);
    height: calc(100% - 8px);
    background-color: $primary;
    z-index: -1;
  }
}
