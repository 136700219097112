body, html, #root {
  height: 100%;
}

body {
  letter-spacing: -1px;
  background: url("../assets/images/BG.png") center bottom no-repeat;
  background-size: cover;
}

img {
  max-width: 100%;
  height: auto;
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  z-index: 100;
}

.cur-pointer {
  cursor: pointer;
}

#walletconnect-wrapper .walletconnect-modal__mobile__toggle a {
  color: #333;
}

.navbar-toggler {
  background-color: $white;
  border-width: 4px !important;
}
