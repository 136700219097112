.card {
  &.card--user {
    $main-color: $cyan;
    background-color: $main-color;
    position: relative;
    $size: 10px;
    border: none;
    margin: $size * 2 $size $size * 2.5;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      background-color: $main-color;
    }

    &:before {
      width: calc(100% - #{$size * 2});
      height: calc(100% + #{$size * 2});
      top: -$size;
      left: $size;
    }

    &:after {
      width: calc(100% - #{$size * 4});
      height: calc(100% + #{$size * 4});
      top: -$size * 2;
      left: $size * 2;
    }

    .card-avatar {
      $avatar-bg: darken($main-color, 10%);
      position: relative;
      width: calc(100% - #{$size * 2});
      margin: $size auto;
      background-color: $avatar-bg;

      img {
        width: 140px;
      }

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        background-color: $avatar-bg;
      }

      &:before {
        width: calc(100% - #{$size * 2});
        height: $size;
        top: -$size;
        left: $size;
      }

      &:after {
        width: calc(100% - #{$size * 4});
        height: $size * 2;
        top: -$size * 2;
        left: $size * 2;
      }
    }

    .card-body {
      $body-shadow: inset 0 -4px 0 0 rgba($black, .1), 0 4px 0 0 rgba($black, .1);
      position: relative;
      background-color: $pink;
      color: $yellow;
      box-shadow: $body-shadow;
      padding: 5px 0;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        width: 10px;
        height: 100%;
        top: 0;
        background-color: $pink;
        box-shadow: $body-shadow;
      }

      &:before {
        right: 100%;
      }

      &:after {
        left: 100%;
      }
    }

    .card-footer {
      background-color: $main-color;
      border-top: none;
      padding-bottom: 0;
    }
  }

  &.card--referral {
    $main-color: #e4b243;
    background-color: transparent;
    position: relative;
    $size: 5px;
    $border: 3px;
    border: none;
    margin: $size * 2;
    text-align: center;

    .card-header,
    .card-footer {
      width: 100%;
      position: relative;
      background-color: $main-color;
      border: $border solid #fff;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        background-color: $main-color;
        z-index: -2;
      }

      &:after {
        width: calc(100% + #{$size * 2 + $border * 2});
        height: calc(100% + #{$size + $border});
        left: $size * -1 - $border;
      }

      &:before {
        width: calc(100% + #{$size * 4 + $border * 2});
        height: calc(100% + #{$border});
        left: $size * -2 - $border;
      }
    }

    .card-header {
      box-shadow:  0 $size * -2 0 0 $main-color;
      border-bottom: none;
      text-transform: uppercase;
      color: $pink;
      padding-top: 0;
      padding-bottom: 0;

      span:nth-child(1) {
        display: inline-block;
        background-color: $main-color;
        padding: 0 10px;
        font-size: 1.2em;
        transform: translateY(-0.7em);
      }

      span:nth-child(2) {
        display: block;
        font-size: 2.5em;
        margin-top: -15px
      }

      &:before {
        bottom: 0;
      }

      &:after {
        bottom: 0;
      }
    }

    .card-footer {
      box-shadow: 0 $size * 2 0 0 $main-color, 0 #{$size * 2 + $border} 0 0 $orange;
      border-top: none;
      
      button {
        position: relative;
        z-index: 10;
      }

      &:before,
      &:after {
        box-shadow: 0 $border 0 0 $orange;
      }

      &:before {
        top: 0;
      }

      &:after {
        top: 0;
      }
    }

    .card-body {
      position: relative;
      background-color: $pink;
      color: $white;
      width: calc(100% - #{$size * 2});
      margin: 0 $size;
      border: $border solid $white;
      border-top: none;
      border-bottom: none;
      box-shadow: $size * -1.5 0 0 0 $pink, $size * 1.5 0 0 0 $pink;
      text-align: center;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% + #{$size * 3 + $border * 2});
        height: 4px;
        top: 0;
        left: $size * -1.5 - $border;
        background-color: rgba($black, .2);
      }
    }
  }

  &.card--tower {
    $main-color: $cyan;
    $secondary-color: darken($cyan, 15%);
    background-color: transparent;
    position: relative;
    $size: 5px;
    $border: 3px;
    border: none;
    margin: $size * 2;
    text-align: center;

    @include media-breakpoint-up(md) {
      width: 380px;
    }

    .card-header,
    .card-footer {
      width: 100%;
      position: relative;
      background-color: $main-color;
      border: $border solid #fff;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        background-color: $main-color;
        z-index: -2;
      }

      &:after {
        width: calc(100% + #{$size * 2 + $border * 2});
        height: calc(100% + #{$size + $border});
        left: $size * -1 - $border;
      }

      &:before {
        width: calc(100% + #{$size * 4 + $border * 2});
        height: calc(100% + #{$border});
        left: $size * -2 - $border;
      }
    }

    .card-header {
      box-shadow:  0 $size * -2 0 0 $main-color;
      border-bottom: none;
      text-transform: uppercase;
      color: $white;
      padding-top: 0;
      padding-bottom: 0;
      position: relative;

      span:nth-child(1) {
        display: block;
        font-size: 1.5em;
        padding: 10px;
      }

      span.level-price {
        position: absolute;
        top: 50%;
        right: 20px;
        color: $yellow;
        font-size: 12px;
        margin-top: -10px;

        img {
          width: 16px;
          vertical-align: top;
          margin-right: -5px;
        }

        @include media-breakpoint-down(lg) {
          position: relative;
          display: block;
          margin: 0 0 10px;
          right: 0;
          top: 0;
          width: 100%;
          text-align: center;
        }
      }

      &:before {
        bottom: 0;
      }

      &:after {
        bottom: 0;
      }
    }

    .card-footer {
      box-shadow: 0 $size * 2 0 0 $main-color, 0 #{$size * 2 + $border} 0 0 $secondary-color;
      border-top: none;

      button {
        position: relative;
        z-index: 10;
      }

      &:before,
      &:after {
        box-shadow: 0 $border 0 0 $secondary-color;
      }

      &:before {
        top: 0;
      }

      &:after {
        top: 0;
      }
    }

    .card-body {
      position: relative;
      background-color: $secondary-color;
      color: $white;
      width: calc(100% - #{$size * 2});
      margin: 0 $size;
      border: $border solid $white;
      border-top: none;
      border-bottom: none;
      box-shadow: $size * -1.5 0 0 0 $secondary-color, $size * 1.5 0 0 0 $secondary-color;
      text-align: center;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% + #{$size * 3 + $border * 2});
        height: 4px;
        top: 0;
        left: $size * -1.5 - $border;
        background-color: rgba($black, .2);
      }
    }
  }
}
