#register {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(lg) {
    overflow: scroll;
    justify-content: unset;
  }
}
